/* eslint-disable @typescript-eslint/space-before-function-paren */
import { useFormContext } from 'react-hook-form'
import { AttributeModel } from 'domain/models'

import { ErrorMessage, FormInputIp } from './styles'

type Props = {
  attribute: AttributeModel
}

const Ipv4Field = ({ attribute }: Props) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  const isRequired = attribute.required ?? false

  const ipValidation = (isRequired: boolean) => {
    const ip = {
      pattern: {
        value: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        message: '* IP inválido'
      },
      required: {
        value: isRequired,
        message: '* Campo obrigatório'
      }
    }
    return ip
  }

  return (
    <FormInputIp>
      <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
      <input
        type="text"
        placeholder='000.000.000.000'
        {...register(attribute.id.toString(), ipValidation(isRequired))}
      />
      {errors[attribute.id.toString()] && (
        <ErrorMessage>{errors[attribute.id.toString()]?.message?.toString()}</ErrorMessage>
      )}
    </FormInputIp>
  )
}

export default Ipv4Field
