import { useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ModalContent } from 'presentation/components'
import { CardModel } from 'domain/models'
import { ColorField, IconField, TextField } from 'presentation/components/Fields'
import InformativeModal from './InformativeModal'
import { useAdmCardContext } from 'presentation/context'
import { useHandleError } from 'presentation/hooks'

import { ModalBodyForm, ModalWrapper } from 'presentation/components/Modal/styles'

interface IFormInput {
  name: string
  color: string
  icon?: string
  image?: string
  isEnable: boolean
}

type Props = {
  toggleModal: () => void
  updateData: () => void
  cardSelected: CardModel
}

const EditCardModal = ({ toggleModal, updateData, cardSelected }: Props) => {
  const defaultValues: IFormInput = {
    name: cardSelected.name,
    icon: cardSelected.icon,
    color: cardSelected.color,
    isEnable: cardSelected.isEnable
  }
  const methods = useForm<IFormInput>({ defaultValues })
  const { editCard } = useAdmCardContext()
  const { handleError } = useHandleError()
  const refInfoModal = useRef<HTMLDialogElement>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false)

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    setIsLoading(true)
    try {
      const isDataChanged =
        cardSelected.name !== data.name ||
        cardSelected.icon !== data.icon ||
        cardSelected.color !== data.color

      if (!isDataChanged) {
        toast.warn('Nada para atualizar!')
        return
      }

      const dto: CardModel = {
        ...cardSelected,
        name: data.name,
        icon: data.icon as string,
        color: data.color
      }

      await editCard.put(dto)
      updateData()
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  const toggleInfoModal = () => {
    if (refInfoModal.current) {
      if (isOpenInfoModal) {
        setIsOpenInfoModal(false)
        refInfoModal?.current.close()
        return
      }
      setIsOpenInfoModal(true)
      refInfoModal?.current.showModal()
    }
  }

  return (
    <>
      <ModalContent
        title="Editar card"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <FormProvider {...methods}>
          <ModalBodyForm>
            <TextField
              attribute={{
                id: 'name',
                question: 'Digite o nome do card',
                required: true,
                isHiddenToVip: false,
                optionsSelectElement: [],
                maxLength: 50
              }}
            />

            <IconField
              name="icon"
              isRequired={true}
              toggleInfoModal={toggleInfoModal}
              defaultValue={cardSelected.icon as IconProp}
            />
            <ColorField name="color" isRequired={true} defaultValue={cardSelected?.color} />
          </ModalBodyForm>
        </FormProvider>
      </ModalContent>
      <ModalWrapper ref={refInfoModal}>
        {isOpenInfoModal && <InformativeModal toggleModal={toggleInfoModal} />}
      </ModalWrapper>
    </>
  )
}

export default EditCardModal
