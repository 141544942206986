import { useEffect, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { ModalContent } from 'presentation/components'
import { EditorContentField, ImageFileField } from 'presentation/components/Fields'
import { reactQuillContainerParams, validations } from './utils'
import { useAdmNewsContext } from 'presentation/context'
import { useHandleError } from 'presentation/hooks'
import { CreateNewsProps } from 'domain/usecases'

import { ModalBodyForm } from 'presentation/components/Modal/styles'
import { FileManagerModel } from 'domain/models'

interface IFormInput {
  image: File
  content: string
}

type Props = {
  toggleModal: () => void
  listLength: number
  updateData: () => void
}

const AddNewsModal = ({ toggleModal, listLength, updateData }: Props) => {
  const methods = useForm<IFormInput>()
  const { saveFile, deleteFile, createNews } = useAdmNewsContext()
  const { handleError } = useHandleError()
  const [isLoading, setIsLoading] = useState(false)
  const modules = useMemo(
    () => ({
      toolbar: {
        container: reactQuillContainerParams
      }
    }),
    []
  )

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    let fileResponse: FileManagerModel[] = []
    if (data.content === '<p><br></p>') {
      methods.setError('content', { type: 'error', message: '* Campo obrigatório' })
      return
    }
    try {
      setIsLoading(true)
      fileResponse = await saveFile.post(data.image)
      const dto: CreateNewsProps = {
        description: data.content,
        urlPicture: fileResponse[0].name,
        weight: listLength + 1
      }
      await createNews.post(dto)

      updateData()
    } catch (error) {
      if (fileResponse[0]?.name) {
        await deleteFile.delete(fileResponse[0]?.name)
      }
      handleError(error)
    } finally {
      setIsLoading(false)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  useEffect(() => {
    if (listLength === 10) {
      toast.error('Já existem 10 destaques!')
      handleCloseModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listLength])

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Novo destaque"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <ModalBodyForm>
          <div className='news-image-adm'>
            <ImageFileField
              attribute={{
                id: 'image',
                question: '',
                optionsSelectElement: [],
                required: true,
                isHiddenToVip: false
              }}
            />
          </div>
          <EditorContentField
            modulesEditor={modules}
            validations={validations}
            fieldName="content"
            label="Adicione um texto"
          />
        </ModalBodyForm>
      </ModalContent>
    </FormProvider>
  )
}

export default AddNewsModal
