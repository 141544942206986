import { OptionSelect } from 'presentation/components/Select/Select'
import { AttributeModel } from 'domain/models'

export const findCategoryOpt = (
  option: number | string,
  categoryOptions: OptionSelect[]
): OptionSelect => {
  return categoryOptions.find((opt: any) => opt.value.id === option) as OptionSelect
}
export const findAttributeOptions = (
  attList: AttributeModel[],
  attributeOptions: OptionSelect[]
): OptionSelect[] => {
  const list: OptionSelect[] = []

  attList.forEach((att) => {
    // Certifique-se de que o ID é comparável
    const result = attributeOptions.find((attOpt: any) => Number(attOpt.value.id) === Number(att.id))

    if (result) {
      list.push(result)
    } else {
      console.log('No matching attribute ID:', att.id)
    }
  })

  return list
}
