import { ChangeEvent, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { AttributeModel, OptionModel } from 'domain/models'

import { ErrorMessage, FormInputTable } from './styles'

type Props = {
  attribute: AttributeModel
  isVip?: boolean
  handleEquipmentSelection?: (list: Record<string, number>) => void
}

const TableField = ({ attribute, isVip = false, handleEquipmentSelection }: Props) => {
  const {
    setValue,
    control,
    formState: { errors },
    unregister
  } = useFormContext()
  const [optionsSelected, setOptionsSelected] = useState<string[]>([])
  const [equipmentSelected, setEquipmentSelected] = useState<Record<string, number>>({})

  const handleChangeNumber = (
    e: ChangeEvent<HTMLInputElement>,
    option: OptionModel,
    optAttribute: AttributeModel
  ) => {
    let newOptions: string[] = optionsSelected

    if (parseInt(e.target.value) > 0) {
      if (!optionsSelected.includes(option?.propertyId as string)) {
        newOptions = [...optionsSelected, option.propertyId as string]
      }
      setValue(optAttribute.id.toString(), e.target.value)
    }

    if (parseInt(e.target.value) === 0) {
      newOptions = optionsSelected.filter((opt) => opt !== option.propertyId)
      unregister(optAttribute.id.toString())
    }

    setOptionsSelected(newOptions)
    handleEquipmentSelected(option.propertyId as string, parseInt(e.target.value))
    return newOptions
  }

  const handleEquipmentSelected = (optionPropId: string, quantity: number) => {
    let _equipments: Record<string, number> = { ...equipmentSelected }
    const _newEquipment: Record<string, number> = {}

    if (optionPropId) {
      _newEquipment[optionPropId] = quantity
    }
    _equipments = { ..._equipments, ..._newEquipment }

    const equipmentsFiltered: Record<string, number> = filterEquipmentByQuantity(_equipments)

    setEquipmentSelected(equipmentsFiltered)
    if (handleEquipmentSelection) {
      handleEquipmentSelection(equipmentsFiltered)
    }
  }

  const filterEquipmentByQuantity = (equipments: Record<string, number>) => {
    let _equipmentsFiltered: Record<string, number> = {}

    for (const [key, value] of Object.entries(equipments)) {
      if (value > 0) {
        const field: Record<string, number> = {}
        field[key] = value
        _equipmentsFiltered = { ..._equipmentsFiltered, ...field }
      }
    }

    return _equipmentsFiltered
  }

  return (
    <Controller
      name={attribute.id.toString()}
      control={control}
      rules={{ required: attribute.required }}
      render={({ field: { onChange } }) => (
        <FormInputTable>
          <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
          <table>
            <thead>
              <tr>{attribute.columnsLabel?.map((label, index) => <th key={index}>{label}</th>)}</tr>
            </thead>
            <tbody>
              {attribute.optionsSelectElement
                .sort((a, b) => (a.weight as number) - (b.weight as number))
                .map((option) => (
                  <tr key={option.id}>
                    <td>{option.value}</td>
                    {option?.attributes?.map((att) => (
                      <td key={att.id}>
                        {att.type === 'number' && !(att.isHiddenToVip && isVip) && (
                          <input
                            type="number"
                            min={0}
                            max={99}
                            defaultValue={0}
                            onChange={(e) => {
                              onChange(handleChangeNumber(e, option, att))
                            }}
                          />
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
          {errors[attribute.id.toString()]?.type === 'required' && (
            <ErrorMessage>* Campo Obrigatório</ErrorMessage>
          )}
        </FormInputTable>
      )}
    />
  )
}

export default TableField
