/* eslint-disable @typescript-eslint/space-before-function-paren */
import { CustomerFollowupModel, IFollowup, ITicket } from 'domain/models'
import { CustomerGetTicket, GetTicketDto, GetTicket } from 'domain/usecases'

export class CustomerGetTicketAdapter implements GetTicket {
  constructor(private readonly customerGetTicket: CustomerGetTicket) { }

  async get(dto: GetTicketDto): Promise<ITicket> {
    const response = await this.customerGetTicket.get(dto)

    if (response) {
      const categoriesFormatted = response.ticket_category?.split('>')
      const descriptionSplicedByQuestion = response?.ticket_content?.split(/\n |\\n/g)
      const descriptionSplicedCleaned = descriptionSplicedByQuestion?.filter(
        (value) => value !== ''
      )
      const descriptionSplicedTrim = descriptionSplicedCleaned?.map((value) => value.trim())
      const title = response.ticket_title.replace('&#62;', '>')

      return {
        id: response.ticket_id || 'ID inválido, por favor, entre em contato conosco.',
        title: title || 'Chamado sem título',
        status: response.ticket_status || 'Status do chamado desconhecido',
        description: descriptionSplicedTrim || ['Chamado sem descrição'],
        group: response.ticket_group || 'Sem grupo solucionador.',
        createdAt: response.ticket_last_mod || 'Chamado sem data de abertura.',
        dueAt: response.ticket_time_to_resolve || 'Chamado sem data de resolução.',
        requesterName: response.ticket_technician || 'Solicitante é desconhecido',
        categories: categoriesFormatted || ['Sem categoria'],
        followups: {
          latestFollowup: response.ticket_followup?.latest_followup ? this.getFollowupFormatted([response.ticket_followup?.latest_followup])[0] : null,
          allFollowups: this.getFollowupFormatted(response.ticket_followup?.all_followups)
        },
        technicianName: response.ticket_technician ?? 'Chamado sem técnico.',
        entities_id: response.ticket_entity ?? 'Chamado sem entidade.'
      }
    }
    return response
  }

  getFollowupFormatted(list: CustomerFollowupModel[]): IFollowup[] {
    const newList: IFollowup[] = []
    if (list && list.length > 0) {
      list.forEach((item) => {
        if (!item.fileId) {
          newList.push({
            createdAt: item?.date,
            description: item?.followup,
            user: item?.user,
            fileName: item.fileName,
            filePastedId: item.filePastedId
          })
        } else {
          newList.push({
            createdAt: item?.date,
            description: item?.followup,
            user: item?.user,
            fileId: item?.fileId ? Array(item?.fileId?.toString()) : null,
            fileName: item?.followup,
            filePastedId: null
          })
        }
      })
    }
    return newList
  }
}
