/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { CategoryInfoButton, CategoryModal, ModalContent } from 'presentation/components'
import { SelectField, TextField } from 'presentation/components/Fields'
import {
  formattedAttributesOptions,
  useGetAttributes,
  useGetCardList,
  useHandleError,
  useSetExtraQuestions
} from 'presentation/hooks'
import PreviewFormModal from './PreviewFormModal'
import { IFormInput } from './AddFormModal'
import {
  buildCardOptions,
  findAttributeOptions,
  removeOptionsId,
  setAttributeToShow
} from './utils'
import { OptionSelect } from 'presentation/components/Select/Select'
import { CategoryType, FormI } from 'domain/models'
import { useAdmFormContext } from 'presentation/context'
import { CreateFormProps } from 'domain/usecases'

import { ModalBodyForm, ModalWrapper } from 'presentation/components/Modal/styles'
import { customFormSelectStyles } from '../Select/styles'
import { customSelectStyles } from './AdmFields/styles'
import AttributeFieldList from './AdmFields/AttributeFieldList'
import { makeGetCategoryList } from 'main/factories/usecases'

type Props = {
  toggleModal: () => void
  updateData: () => void
  form: FormI
}

const EditFormModal = ({ toggleModal, updateData, form }: Props) => {
  const { getCardList, getCategoryList, getAttributeList, editForm } = useAdmFormContext()
  const { isLoadingAttributes, attributeOptions, attributeOptionsAll } = useGetAttributes({ getAttributeList })
  const { isLoadingCardList, cardList, getCardsData } = useGetCardList({ getCardList })
  const defaultValues: IFormInput = {
    title: form?.title ?? '',
    attReused: form?.attributes ?? [],
    associatedCard: form.associatedCard,
    attributes: [],
    category: form.category
  }

  const methods = useForm<IFormInput>({ defaultValues })
  const {
    optionListSelected,
    setOptionListSelected,
    handleCheckboxExtraQuestions,
    handleSelectExtraQuestions
  } = useSetExtraQuestions({ methods })
  const refCategoryModal = useRef<HTMLDialogElement>(null)
  const { handleError } = useHandleError()
  const refPreviewModal = useRef<HTMLDialogElement>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [previewContent, setPreviewContent] = useState<FormI>({} as FormI)
  const [cardOptions, setCardOptions] = useState<OptionSelect[]>([])
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false)
  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false)
  const [attributesToRender, setAttributesToRender] = useState<OptionSelect[]>([])

  const toggleCategoryModal = () => {
    if (refCategoryModal.current) {
      if (isOpenCategoryModal) {
        setIsOpenCategoryModal(false)
        refCategoryModal?.current.close()
        return
      }
      setIsOpenCategoryModal(true)
      refCategoryModal?.current.showModal()
    }
  }

  const togglePreviewModal = async () => {
    if (refPreviewModal.current) {
      if (isOpenPreviewModal) {
        setIsOpenPreviewModal(false)
        setOptionListSelected([])
        refPreviewModal?.current.close()
        return
      }

      const result = setAttributeToShow({
        attrsCreated: methods.getValues('attributes'),
        attrsReused: methods.getValues('attReused')
      })

      setPreviewContent({
        id: 1,
        title: methods.getValues('title'),
        isEnable: true,
        category: methods.getValues('category'),
        attributes: result ?? [],
        color: '',
        parentName: '',
        articles: [],
        parentCard: ''
      })
      setIsOpenPreviewModal(true)
      refPreviewModal?.current.showModal()
    }
  }

  const checkDataChanges = (dto: CreateFormProps): boolean => {
    const originalData = {
      title: form.title,
      isEnable: form.isEnable,
      associatedCard: form.associatedCard,
      attributes: form.attributes,
      categoryId: form?.category?.categoryId,
      type: form?.category?.type,
      group: form?.category?.group,
      categoryName: form?.category?.name,
      openingStatus: form?.category?.status
    }
    return JSON.stringify(originalData) === JSON.stringify(dto)
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    try {
      setIsLoading(true)
      const _attributes = removeOptionsId(data.attributes)
      const dto: CreateFormProps = {
        title: data.title,
        isEnable: form.isEnable,
        associatedCard: data.associatedCard || null,
        attributes: [...data.attReused, ..._attributes],
        categoryId: data?.category?.categoryId ?? null,
        type: data?.category?.type ?? null,
        group: data?.category?.group ?? null,
        categoryName: data?.category?.name ?? null,
        openingStatus: data?.category?.status ?? null,
        entityId: data?.category?.entityId ?? ''
      }

      const isEqual = checkDataChanges(dto)
      if (isEqual) {
        toast.warn('Nada a ser editado!')
        toggleModal()
        return
      }

      await editForm.put(form.id, dto)
      updateData()
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  useEffect(() => {
    if (!isLoadingCardList && cardList.length > 0) {
      const result = buildCardOptions(cardList)
      setCardOptions(result)
    }
  }, [isLoadingCardList, cardList])

  useEffect(() => {
    getCardsData()
  }, [])
  useEffect(() => {
    const attributesToRender = findAttributeOptions(form.attributes, attributeOptions)
  }, [form.attributes, attributeOptions])

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Editar formulário"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
        handleOpenModal={togglePreviewModal}
        btn2Text="Visualizar"
      >
        <ModalBodyForm>
          <TextField
            attribute={{
              id: 'title',
              question: 'Digite o título do formulário:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false
            }}
          />
          <CategoryInfoButton
            categorySelected={methods.getValues('category')}
            toggleCategoryModal={toggleCategoryModal}
          />

          <SelectField
            attribute={{
              id: 'associatedCard',
              question: 'Selecione o card ao qual estará associado o formulário:',
              optionsSelectElement: [],
              required: false,
              isHiddenToVip: false
            }}
            options={cardOptions}
            menuPlacement={'bottom'}
            customSelectStyles={customFormSelectStyles}
            isLoadingData={isLoadingCardList}
            defaultValue={
              form.associatedCard ? [
                {
                  label: form?.ancestorsCardTreeName ?? '',
                  value: {
                    id: form.associatedCard?.id as number,
                    title: form.associatedCard?.name ?? ''
                  }
                }
              ] : undefined
            }
          />

          <SelectField
            attribute={{
              id: 'attReused',
              question: 'Selecione as perguntas deseja reaproveitar/remover:',
              optionsSelectElement: [],
              required: false,
              isHiddenToVip: false
            }}
            options={attributeOptions}
            menuPlacement={'bottom'}
            customSelectStyles={customFormSelectStyles}
            isLoadingData={isLoadingAttributes}
            isMulti={true}
            defaultValue={formattedAttributesOptions(form.attributes)}
          />
          <AttributeFieldList<IFormInput>
            attributeFieldName="attributes"
            firstLevel={true}
          />
        </ModalBodyForm>
      </ModalContent>
      <ModalWrapper ref={refPreviewModal}>
        {isOpenPreviewModal && (
          <PreviewFormModal
            toggleModal={togglePreviewModal}
            attributes={previewContent.attributes}
            optionListSelected={optionListSelected}
            handleCheckboxExtraQuestions={handleCheckboxExtraQuestions}
            handleSelectExtraQuestions={handleSelectExtraQuestions}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refCategoryModal} $width="70%">
        {isOpenCategoryModal && <CategoryModal toggleModal={toggleCategoryModal} getCategoryList={makeGetCategoryList()} />}
      </ModalWrapper>
    </FormProvider>
  )
}

export default EditFormModal
