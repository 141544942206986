import { ChangeEvent } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { AttributeModel, AttributeOptionModel } from '../../../domain/models'

import { ErrorMessage, FormInputRadio } from './styles'

type Props = {
  attribute: AttributeModel
  handleOptionSelected: (value: string) => void
  errorCategory?: string | null
}

const RadioField = ({ attribute, handleOptionSelected, errorCategory = null }: Props) => {
  const {
    control,
    formState: { errors },
    getValues,
    clearErrors
  } = useFormContext()

  const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
    clearErrors(attribute.id.toString())

    if (e?.target.value) {
      handleOptionSelected(e.target.value)
    }
    return e.target.value
  }

  return (
    <Controller
      name={attribute.id.toString()}
      control={control}
      rules={{ required: attribute.required }}
      render={({ field: { onChange } }) => (
        <FormInputRadio>
          <div className="wrapper_radio">
            <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
            <div className="radio-list">
              {attribute.optionsSelectElement
                .sort((a, b) => (a.weight as number) - (b.weight as number))
                .map((option: AttributeOptionModel) => (
                  <div key={option.id} className="input-radio">
                    <input
                      type="radio"
                      name={`${attribute.id.toString()}-${option?.id}`}
                      id={option?.id.toString()}
                      value={option.value}
                      checked={option.value === getValues(attribute.id.toString())}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onChange(handleSelect(e))
                      }}
                    />
                    <p>{option.value}</p>
                  </div>
                ))}
            </div>
          </div>
          {errors[attribute.id.toString()]?.type === 'required' && (
            <ErrorMessage>* Campo Obrigatório</ErrorMessage>
          )}
          {errorCategory && <ErrorMessage>{errorCategory}</ErrorMessage>}
        </FormInputRadio>
      )}
    />
  )
}

export default RadioField
