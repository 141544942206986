import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

import { Container } from './styles'

function Search () {
  const [isHovered, setIsHovered] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [valueToSearch, setValueToSearch] = useState('')
  const showSearchInput = isHovered || isFocused || valueToSearch !== ''

  return (
    <Container
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
      onFocus={() => {
        setIsFocused(true)
      }}
      onBlur={() => {
        setIsFocused(false)
      }}
    >
      {showSearchInput && (
        <input
          placeholder="Digite o nome do card"
          onChange={(e) => {
            setValueToSearch(e.target.value)
          }}
        />
      )}
      {showSearchInput ? (
        <FontAwesomeIcon className="arrow-icon" icon={faArrowRight} />
      ) : (
        <FontAwesomeIcon className="search-icon" icon={faMagnifyingGlass} />
      )}
    </Container>
  )
}

export default Search
