import { useState } from 'react'
import { CategoryModel, CategoryType, FormI, ICategory } from 'domain/models'
import { GetForm } from 'domain/usecases'
import { useHandleError } from '.'

type Props = {
  getForm: GetForm
}

export const useGetForm = ({ getForm }: Props) => {
  const [form, setForm] = useState<FormI | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const { handleError } = useHandleError()

  const getFormData = async (formId: string) => {
    try {
      setIsLoading(true)
      const response = await getForm.get(formId)

      const labelName = response.ancestorsCardTreeName ? response.ancestorsCardTreeName.split('>')[0] : response.associatedCard?.name

      const category: ICategory | null = response.categoryId ? {
        categoryId: response.categoryId,
        name: response.categoryName as string,
        type: response.type as unknown as CategoryType,
        group: response.group as string,
        status: (response.openingStatus as string) ?? 'DIR',
        entityId: response.entityId
      } : null

      setForm({
        id: response.id,
        title: response.title,
        isEnable: response.isEnable,
        category,
        attributes: response.attributes,
        color: response.associatedCard?.color,
        parentName: labelName,
        articles: response.articles ?? [],
        parentCard: response.associatedCard?.name,
        ancestorsCardTreeName: response?.ancestorsCardTreeName,
        associatedCard: response?.associatedCard
      })
    } catch (error: any) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoadingForm: isLoading,
    form,
    getFormData
  }
}
