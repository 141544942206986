import { IGetLocationInfo } from 'domain/models/locationInfo/location'
import { SearchLocation } from 'domain/usecases/user/search-location'
import { useState } from 'react'
import { useHandleError } from './useHandleError'

type Props = {
  getLocation: SearchLocation
}

type GetDataProps = {
  name: string
  limit: number
}

export const useGetLocation = ({ getLocation }: Props) => {
  const [locationList, setLocationList] = useState<IGetLocationInfo[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { handleError } = useHandleError()

  const searchLocation = async ({ name, limit }: GetDataProps) => {
    try {
      setIsLoading(true)
      const response = await getLocation.get(name)
      setLocationList(response)
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    locationList,
    setLocationList,
    isLoading,
    setIsLoading,
    searchLocation
  }
}
