/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { AttributeModel, IGetUserInfo } from 'domain/models'
import { getCurrentAccountAdapter } from 'main/adapters'
import { useTicketFormContext } from 'presentation/context'
import { useHandleError } from 'presentation/hooks'

import { ErrorMessage, FormInputCheckbox, FormInputSearch } from './styles'
import Spinner from '../Loadings/Spinner'

type Props = {
  attribute: AttributeModel
  setUserName: (value: string) => void
}

const UserField = ({ attribute, setUserName }: Props) => {
  const {
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useFormContext()
  const { searchUserByName } = useTicketFormContext()
  const { handleError } = useHandleError()

  const [userList, setUserList] = useState<IGetUserInfo[]>([])
  const [userSelected, setUserSelected] = useState<string | null>(null)
  const [userNameTyped, setUserNameTyped] = useState('')
  const [isForOtherUser, setIsForOtherUser] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const userName = getCurrentAccountAdapter()?.name

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setIsForOtherUser(e.target.checked)
  }

  const handleSearchUser = async (value: string) => {
    try {
      const response = await searchUserByName.get(value)
      setUserList(response)
    } catch (error: any) {
      setUserList([])
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUserSelected = (user: IGetUserInfo) => {
    clearErrors(attribute?.optionsSelectElement[0]?.attributes[0]?.id.toString())
    setUserName(user.fullName)
    setUserSelected(user.fullName)
    setValue(attribute?.optionsSelectElement[0]?.attributes[0]?.id.toString(), user.login)
    setUserList([])
    setUserNameTyped('')
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Delete' || e.key === 'Backspace') {
      setUserSelected(null)
      setUserNameTyped('')
    }
  }

  useEffect(() => {
    if (!userSelected && isForOtherUser) {
      setError(attribute?.optionsSelectElement[0]?.attributes[0]?.id.toString(), {
        type: 'error',
        message: '* Campo obrigatório'
      })
    }
  }, [userSelected, userList, isForOtherUser])

  useEffect(() => {
    if (!isForOtherUser && attribute?.optionsSelectElement[0]?.attributes) {
      const userLogin = getCurrentAccountAdapter()?.login
      setUserName(userName)
      setUserList([])
      setUserSelected(null)
      setUserNameTyped('')
      setValue(attribute?.optionsSelectElement[0]?.attributes[0]?.id.toString(), userLogin)
      clearErrors(attribute?.optionsSelectElement[0]?.attributes[0]?.id.toString())
    }
  }, [isForOtherUser])

  useEffect(() => {
    if (userNameTyped === '') {
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    const getData = setTimeout(() => {
      handleSearchUser(userNameTyped)
    }, 3000)

    return () => {
      clearTimeout(getData)
    }
  }, [userNameTyped])

  return (
    <>
      <FormInputCheckbox>
        <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
        <div className="input-checkbox">
          <input type="checkbox" name={attribute.id.toString()} onChange={handleCheckbox} />
          <p>Não</p>
        </div>
      </FormInputCheckbox>
      {isForOtherUser && (
        <FormInputSearch>
          <div className="input-search">
            <label htmlFor={attribute?.optionsSelectElement[0]?.attributes[0]?.id.toString()}>
              {attribute?.optionsSelectElement[0]?.attributes[0]?.question}
            </label>
            <div className="input-search__loading">
              <input
                type="search"
                name={attribute?.optionsSelectElement[0]?.attributes[0]?.id.toString()}
                autoComplete="off"
                placeholder="Escreva o nome completo"
                value={userSelected ?? userNameTyped}
                onChange={(e) => {
                  setUserNameTyped(e.target.value)
                }}
                onKeyDown={(e) => {
                  handleKeyDown(e)
                }}
              />
              {isLoading && (
                <span className="loading">
                  {' '}
                  <Spinner size={20} marginTop="1rem" />
                </span>
              )}
            </div>
          </div>
          {!isLoading && userList.length > 0 && (
            <div className="input-search-list">
              <ul>
                {userList.map((user, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      handleUserSelected(user)
                    }}
                  >
                    {user?.fullName.toUpperCase()}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {!isLoading && userList.length === 0 && userNameTyped && (
            <div className="input-search-list">
              <div className="input-search-item">Nenhum usuário encontrado</div>
            </div>
          )}
          {!isLoading && errors[attribute?.optionsSelectElement[0]?.attributes[0]?.id.toString()] && (
            <ErrorMessage>
              {errors[attribute?.optionsSelectElement[0]?.attributes[0]?.id.toString()]?.message?.toString()}
            </ErrorMessage>
          )}
        </FormInputSearch>
      )}
    </>
  )
}

export default UserField
