import { customStyles, MessageEmptyCard } from '../styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { SelectField } from 'presentation/components/Fields'
import { buildOptions } from './buildCardOptions'
import { OptionsCards } from '../SubCardsModal'

type Props = {
  card: OptionsCards
  handleOptionSelected: (value: string | any) => void
}

export const RenderCardComponent = ({ card, handleOptionSelected }: Props) => {
  const activeSubCards = card?.children.filter((card) => card.isEnable)

  return (
    <>
      {activeSubCards && activeSubCards.length > 0 && (
        <SelectField
          key={card.id}
          attribute={{
            id: card.id,
            question: '',
            optionsSelectElement: [],
            required: true,
            isHiddenToVip: false
          }}
          options={buildOptions(activeSubCards, card.hierarchy)}
          menuPlacement={'bottom'}
          customSelectStyles={customStyles}
          handleOptionSelected={handleOptionSelected}
        />
      )}
      {activeSubCards.length === 0 && (
        <MessageEmptyCard>
          <FontAwesomeIcon icon={faCircleExclamation} />
          <p>No momento não existe nenhum card associado a esta página.</p>
        </MessageEmptyCard>
      )}
    </>
  )
}
