import { useFormContext } from 'react-hook-form'
import { AttributeModel } from 'domain/models'

import { ErrorMessage, FormInputTime } from './styles'

type Props = {
  attribute: AttributeModel
}

const TimeField = ({ attribute }: Props) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  const isRequired = attribute.required ?? false

  return (
    <FormInputTime>
      <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
      <input
        type="time"
        {...register(attribute.id.toString(), { required: isRequired })}
      />
      {errors[attribute.id.toString()]?.type === 'required' && (
        <ErrorMessage>* Campo obrigatório</ErrorMessage>
      )}
    </FormInputTime>
  )
}

export default TimeField
