/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Button, CategoryInfoButton, CategoryModal, ModalContent } from 'presentation/components'
import { SelectField, TextField } from 'presentation/components/Fields'
import {
  useGetAttributes,
  useGetCardList,
  useHandleError,
  useSetExtraQuestions
} from 'presentation/hooks'
import AttributeFieldList, { IAttributeProps } from './AdmFields/AttributeFieldList'
import PreviewFormModal from './PreviewFormModal'
import { buildCardOptions, removeOptionsId, setAttributeToShow } from './utils'
import { OptionSelect } from 'presentation/components/Select/Select'
import { CategoryModel, CategoryType, FormI, ICategory } from 'domain/models'
import { useAdmFormContext } from 'presentation/context'
import { v4 as uuidv4 } from 'uuid'
import { ModalBodyForm, ModalWrapper } from 'presentation/components/Modal/styles'
import { customFormSelectStyles } from '../Select/styles'
import { CreateFormProps } from 'domain/usecases'
import { makeGetCategoryList } from 'main/factories/usecases'
export interface IFormInput {
  title: string
  associatedCard: any
  attributes: IAttributeProps[] | []
  attReused: any[] | []
  category: null | Pick<ICategory, 'entityId' | 'name' | 'type' | 'categoryId' | 'group' | 'status'>
}

type Props = {
  toggleModal: () => void
  updateData: () => void
}

const AddFormModal = ({ toggleModal, updateData }: Props) => {
  const defaultValues: IFormInput = {
    title: '',
    attReused: [],
    associatedCard: '',
    attributes: [
      {
        name: `question-${uuidv4()}`,
        question: '',
        optionsSelectElement: [],
        required: true,
        isHiddenToVip: false,
        type: 'text',
        positionOrder: 1,
        isVisibleToDescription: true
      }
    ],
    category: null
  }
  const methods = useForm<IFormInput>({ defaultValues })
  const {
    optionListSelected,
    setOptionListSelected,
    handleCheckboxExtraQuestions,
    handleSelectExtraQuestions
  } = useSetExtraQuestions({ methods })
  const { getCardList, createForm, getAttributeList } = useAdmFormContext()
  const { isLoadingAttributes, attributeOptions } = useGetAttributes({ getAttributeList })
  const { isLoadingCardList, cardList, getCardsData } = useGetCardList({ getCardList })
  const refCategoryModal = useRef<HTMLDialogElement>(null)
  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false)
  const refPreviewModal = useRef<HTMLDialogElement>(null)
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [previewContent, setPreviewContent] = useState<FormI>({} as FormI)
  const [cardOptions, setCardOptions] = useState<OptionSelect[]>([])
  const { handleError } = useHandleError()
  const toggleCategoryModal = () => {
    if (refCategoryModal.current) {
      if (isOpenCategoryModal) {
        setIsOpenCategoryModal(false)
        refCategoryModal?.current.close()
        return
      }
      setIsOpenCategoryModal(true)
      refCategoryModal?.current.showModal()
    }
  }
  const togglePreviewModal = async () => {
    if (refPreviewModal.current) {
      if (isOpenPreviewModal) {
        setIsOpenPreviewModal(false)
        setOptionListSelected([])
        refPreviewModal?.current.close()
        return
      }

      const result = setAttributeToShow({
        attrsCreated: methods.getValues('attributes'),
        attrsReused: methods.getValues('attReused')
      })
      setPreviewContent({
        id: 1,
        title: methods.getValues('title'),
        isEnable: true,
        category: methods.getValues('category'),
        attributes: result ?? [],
        color: '',
        parentName: '',
        articles: [],
        parentCard: ''
      })
      setIsOpenPreviewModal(true)
      refPreviewModal?.current.showModal()
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    try {
      const _attributes = removeOptionsId(data.attributes)
      const dto: CreateFormProps = {
        title: data.title,
        isEnable: true,
        associatedCard: data.associatedCard || null,
        attributes: [...data.attReused, ..._attributes],
        categoryId: data?.category?.categoryId ?? null,
        type: data?.category?.type ?? null,
        group: data?.category?.group ?? null,
        categoryName: data?.category?.name ?? null,
        openingStatus: data?.category?.status ?? null,
        entityId: data?.category?.entityId ?? ''
      }
      await createForm.post(dto)
      updateData()
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  useEffect(() => {
    if (!isLoadingCardList && cardList.length > 0) {
      const result = buildCardOptions(cardList)
      setCardOptions(result)
    }
  }, [isLoadingCardList, cardList])

  useEffect(() => {
    getCardsData()
  }, [])

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Novo formulário"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
        handleOpenModal={togglePreviewModal}
        btn2Text="Visualizar"
      >
        <ModalBodyForm>
          <TextField
            attribute={{
              id: 'title',
              question: 'Digite o título do formulário:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false
            }}
          />
          <CategoryInfoButton
            categorySelected={methods.getValues('category')}
            toggleCategoryModal={toggleCategoryModal}
          />
          <SelectField
            attribute={{
              id: 'associatedCard',
              question: 'Selecione o card ao qual estará associado o formulário:',
              optionsSelectElement: [],
              required: false,
              isHiddenToVip: false
            }}
            options={cardOptions}
            menuPlacement={'bottom'}
            customSelectStyles={customFormSelectStyles}
            isLoadingData={isLoadingCardList}
          />

          <SelectField
            attribute={{
              id: 'attReused',
              question: 'Selecione as perguntas que deseja reaproveitar:',
              optionsSelectElement: [],
              required: false,
              isHiddenToVip: false
            }}
            options={attributeOptions}
            menuPlacement={'bottom'}
            customSelectStyles={customFormSelectStyles}
            isLoadingData={isLoadingAttributes}
            isMulti={true}
          />

          <AttributeFieldList<IFormInput>
            attributeFieldName="attributes"
            firstLevel={true} // TO AVOID INFINITY CYCLE OF SELECT <-> SELECT
          />
        </ModalBodyForm>
      </ModalContent>
      <ModalWrapper ref={refPreviewModal}>
        {isOpenPreviewModal && (
          <PreviewFormModal
            toggleModal={togglePreviewModal}
            attributes={previewContent.attributes}
            optionListSelected={optionListSelected}
            handleCheckboxExtraQuestions={handleCheckboxExtraQuestions}
            handleSelectExtraQuestions={handleSelectExtraQuestions}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refCategoryModal} $width="70%">
        {isOpenCategoryModal && <CategoryModal toggleModal={toggleCategoryModal} getCategoryList={makeGetCategoryList()} />}
      </ModalWrapper>
    </FormProvider>
  )
}

export default AddFormModal
