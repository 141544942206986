/* eslint-disable @typescript-eslint/space-before-function-paren */
import { useEffect, useState } from 'react'
import { OptionSelect } from 'presentation/components/Select/Select'
import { AttributeModel, AttributePaginationModel } from 'domain/models'
import { GetAttributeList } from 'domain/usecases'
import { useHandleError } from './useHandleError'

type Props = {
  getAttributeList: GetAttributeList
}

export const useGetAttributes = ({ getAttributeList }: Props) => {
  const [isLoadingAttributes, setIsLoadingAttributes] = useState(false)
  const [attributes, setAttributes] = useState<AttributeModel[]>([])
  const [attributeOptions, setAttributeOptions] = useState<OptionSelect[]>([])
  const [attributeOptionsAll, setAttributeOptionsAll] = useState<OptionSelect[]>([])
  const [pagination, setPagination] = useState({
    next: true,
    prev: false,
    page: 0
  })
  const { handleError } = useHandleError()

  async function getAttributeData(page: number = 0) {
    setIsLoadingAttributes(true)
    try {
      const response = await getAttributeList.get({ page, limit: 999, order: 'DESC' })

      if (response.data.length > 0) {
        const _attributeFilteredByType = filterAttributesByType(response.data)
        // Filter and format attributes
        // const _attributesWithoutOptions = filterAttributesWithoutOptions(response.data)
        const userAtt = response.data.filter((attribute) => attribute.type === 'user')

        const _attributeOptions = formattedAttributesOptions([
          ...userAtt,
          ..._attributeFilteredByType
          // ..._attributesWithoutOptions
        ])
        const _attributeOptionsAll = formattedAttributesOptions([...response.data])

        setAttributes(_attributeFilteredByType)
        setAttributeOptions(_attributeOptions)
        setAttributeOptionsAll(_attributeOptionsAll)
        setPagination({
          page,
          next: response.paginate.next,
          prev: response.paginate.prev
        })
      }
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoadingAttributes(false)
    }
  }

  useEffect(() => {
    getAttributeData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isLoadingAttributes,
    attributes,
    attributeOptions,
    attributeOptionsAll,
    pagination,
    getAttributeData,
    setAttributeOptionsAll
  }
}

export const formattedAttributesOptions = (list: AttributeModel[]) => {
  const _attributeOptions: OptionSelect[] = []
  list.forEach((att) => {
    _attributeOptions.push({
      label: att.question,
      value: { ...att }
    })
  })
  return _attributeOptions
}

export const filterAttributesByType = (list: AttributeModel[]): AttributeModel[] => {
  const filter1 = list.filter((attribute) => attribute.type !== 'number')
  const filter2 = filter1.filter((attribute) => attribute.type !== 'table')
  const filter3 = filter2.filter((attribute) => attribute.type !== 'user')
  const filter4 = filter3.filter((attribute) => attribute.type !== 'search')
  // const filter5 = filter4.filter((attribute) => attribute.type !== 'select')
  // const filter6 = filter5.filter((attribute) => attribute.type !== 'checkbox')

  return filter4
}
