import { Controller, useFormContext } from 'react-hook-form'
import { MenuPlacement, MultiValue, SingleValue } from 'react-select'
import { Select, Spinner } from '..'
import { OptionSelect } from 'presentation/components/Select/Select'
import { AttributeModel } from 'domain/models'

import { ErrorMessage, FormFieldSelect } from './styles'

type Props = {
  options: OptionSelect[]
  attribute: AttributeModel
  isMulti?: boolean
  handleOptionSelected?: ((value: string) => void) | null
  defaultValue?: OptionSelect[]
  isLoadingData?: boolean
  customSelectStyles?: object | null
  menuPlacement?: MenuPlacement
  errorCategory?: string | null
}

const SelectField = (props: Props) => {
  const {
    options,
    attribute,
    isMulti = false,
    handleOptionSelected = null,
    defaultValue,
    isLoadingData = false,
    customSelectStyles,
    menuPlacement,
    errorCategory = null
  } = props
  const {
    control,
    clearErrors,
    getValues,
    formState: { errors }
  } = useFormContext()

  const handleSelect = (
    e: SingleValue<OptionSelect> | MultiValue<OptionSelect>
  ): string | string[] => {
    clearErrors(attribute.id.toString())
    if (handleOptionSelected && !isMulti) {
      const value = (e as SingleValue<OptionSelect>)?.value
      handleOptionSelected(value as string)
    }
    if (Array.isArray(e)) {
      const values: string[] = []
      e.forEach((element) => {
        values.push(element.value)
      })
      return values
    }
    const value = (e as SingleValue<OptionSelect>)?.value
    return value as string
  }

  const validations = {
    required: {
      value: attribute.required ?? false,
      message: '* Campo obrigatório'
    }
  }

  return (
    <Controller
      name={attribute.id.toString()}
      control={control}
      rules={validations}
      render={({ field: { onChange } }) => (
        <FormFieldSelect>
          <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
          {isLoadingData && <Spinner size={25} marginTop="1rem" />}
          {!isLoadingData && (
            <Select
              onChange={(e: SingleValue<OptionSelect> | MultiValue<OptionSelect>) => {
                onChange(handleSelect(e))
              }}
              optionValues={options}
              isMulti={isMulti}
              styles={customSelectStyles}
              defaultValue={defaultValue}
              menuPlacement={menuPlacement}
            />
          )}
          {errors[attribute.id.toString()] && (
            <ErrorMessage>{errors[attribute.id.toString()]?.message?.toString()}</ErrorMessage>
          )}
          {errorCategory && (
            <ErrorMessage>{errorCategory}</ErrorMessage>
          )}
        </FormFieldSelect>
      )}
    />
  )
}

export default SelectField
