import { Controller, useFormContext } from 'react-hook-form'
import { AttributeModel } from 'domain/models'
import { ErrorMessage, FormInputDate } from './styles'

type Props = {
  attribute: AttributeModel
}
const DateField = ({ attribute }: Props) => {
  const {
    formState: { errors }
  } = useFormContext()

  const convertDate = (date: string) => {
    // Convert format dd/mm/yyyy to dd-mm-yyyy
    const dateSplit = date.split('-')
    dateSplit.reverse()
    const newDate = `${dateSplit[0]}-${dateSplit[1]}-${dateSplit[2]}`
    return newDate
  }
  return (
    <Controller
      name={attribute.id.toString()}
      rules={{ required: attribute.required }}
      render={({ field: { onChange } }) => (
        <FormInputDate>
          <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
          <input
            type="date"
            placeholder={attribute.placeholder ?? 'dd/mm/yyyy'}
            max={'2120-12-31'}
            min={'1900-12-31'}
            onChange={(e) => {
              onChange(convertDate(e.target.value))
            }}
          />
          {errors[attribute.id.toString()] && (
            <ErrorMessage>{errors[attribute.id.toString()]?.message?.toString()}</ErrorMessage>
          )}
        </FormInputDate>
      )}
    />
  )
}

export default DateField
