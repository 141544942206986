import { useState } from 'react'
import { MoonLoader } from 'react-spinners'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { IFollowup } from 'domain/models'
import { useTicketDetailsContext } from 'presentation/context'
import { useHandleError } from 'presentation/hooks'

import { WrapperFollowup } from './styles'

type Props = {
  followup: IFollowup
}

const FollowupItem = ({ followup }: Props) => {
  const { getFileBase64 } = useTicketDetailsContext()
  const { handleError } = useHandleError()
  const [isLoading, setIsLoading] = useState(false)

  const handleDownload = async (fileId: string) => {
    setIsLoading(true)
    try {
      const response = await getFileBase64.get(fileId)

      const downloadLink = document.createElement('a')
      downloadLink.href = response
      downloadLink.download = fileId
      downloadLink.click()
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <WrapperFollowup>
      <div className="followup-info">
        <div className="followup-info__date">
          <FontAwesomeIcon icon={faCircle} />
          <span className="create-info">Criado em:</span>
          <span className="data-followup">{followup.createdAt.replace(',', '')}</span>
        </div>
        {followup.user && (
          <div className="followup-info__name">
            <span className="create-info">Por:</span>
            <span className="data-followup">{followup.user}</span>
          </div>
        )}
      </div>
      <div className="followup-description">
        {followup?.fileId ?? followup.filePastedId ? (
          <>
            {followup.filePastedId ? (
              <>
                <span className="followup-description__text">{followup.description}</span>
                <div className="followup-description__file">
                  {followup?.filePastedId?.map((item, index) => (
                    <div className="file" key={index}>
                      <button
                        title="Baixar documento"
                        onClick={() => {
                          handleDownload(item)
                        }}
                        disabled={isLoading}
                      >
                        {`Adicionou documento: ${item} `}
                      </button>
                    </div>
                  ))}
                  {isLoading && <MoonLoader size={15} color={'#123abc'} />}
                </div>
              </>
            ) : (
              <div className="followup-description__file">
                <span>Vincular documento:</span>
                {followup?.fileId?.map((item, index) => (
                  <div className="file" key={index}>
                    <button
                      title="Baixar documento"
                      onClick={() => {
                        handleDownload(item)
                      }}
                      disabled={isLoading}
                    >
                      {followup.fileName}
                    </button>
                  </div>
                ))}
                {isLoading && <MoonLoader size={15} color={'#123abc'} />}
              </div>
            )}
          </>
        ) : (
          <span className="followup-description__text">{followup.description}</span>
        )}
      </div>
    </WrapperFollowup>
  )
}

export default FollowupItem
