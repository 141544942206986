/* eslint-disable @typescript-eslint/indent */
import { ChangeEvent, Fragment } from 'react'
import {
  CpfField,
  EmailField,
  FileField,
  CheckboxField,
  PhoneField,
  SelectField,
  TextField,
  TextareaField,
  TimeField,
  UserField,
  TableField,
  DownloadField,
  RadioField,
  DateField,
  Ipv4Field
} from '../Fields'
import { OptionListI } from 'presentation/hooks'
import { setOptions } from './utils'
import { AttributeModel, OptionModel } from 'domain/models'

type Props = {
  attributes: AttributeModel[]
  isVip: boolean
  setUserName: (value: string) => void
  handleCheckboxExtraQuestions: (
    e: ChangeEvent<HTMLInputElement>,
    option: OptionModel,
    attName: string,
  ) => void
  handleSelectExtraQuestions: (option: string, attribute: AttributeModel) => void
  optionListSelected?: OptionListI[]
  handleEquipmentSelection?: (list: Record<string, number>) => void
}

export const InputsToRender = ({
  attributes,
  isVip,
  setUserName,
  handleCheckboxExtraQuestions,
  handleSelectExtraQuestions,
  optionListSelected,
  handleEquipmentSelection
  // setLocationId
}: Props) => {
  return (
    <>
      {attributes.length > 0 &&
        attributes
          .sort((a, b) => (a.positionOrder as number) - (b.positionOrder as number))
          .map((attribute) => (
            <Fragment key={attribute.id}>
              {attribute.type === 'user' && !(attribute.isHiddenToVip && isVip) && (
                <UserField attribute={attribute} setUserName={setUserName} />
              )}
              {attribute.type === 'file' && !(attribute.isHiddenToVip && isVip) && (
                <FileField attribute={attribute} />
              )}

              {attribute.type === 'ipv4' && !(attribute.isHiddenToVip && isVip) && (
                <Ipv4Field attribute={attribute} />
              )}

              {attribute.type === 'text' && !(attribute.isHiddenToVip && isVip) && (
                <TextField attribute={attribute} />
              )}

              {attribute.type === 'CPF' && !(attribute.isHiddenToVip && isVip) && (
                <CpfField attribute={attribute} />
              )}

              {attribute.type === 'textarea' && !(attribute.isHiddenToVip && isVip) && (
                <TextareaField attribute={attribute} />
              )}
              {attribute.type === 'phone' && !(attribute.isHiddenToVip && isVip) && (
                <PhoneField attribute={attribute} />
              )}

              {attribute.type === 'time' && !(attribute.isHiddenToVip && isVip) && (
                <TimeField attribute={attribute} />
              )}
              {attribute.type === 'email' && !(attribute.isHiddenToVip && isVip) && (
                <EmailField attribute={attribute} />
              )}

              {attribute.type === 'checkbox' &&
                attribute?.optionsSelectElement?.length > 0 &&
                !(attribute.isHiddenToVip && isVip) && (
                  <>
                    <CheckboxField
                      attribute={attribute}
                      handleOptionsSelected={(e, option) => {
                        handleCheckboxExtraQuestions(e, option, attribute.id.toString())
                      }}
                    />
                    {attribute.optionsSelectElement.length > 0 &&
                      attribute.optionsSelectElement.map((option) => {
                        const optSelect = optionListSelected?.find(
                          (op) => op.value === option.value
                        )

                        if (option?.attributes?.length > 0 && optSelect?.value === option.value) {
                          return (
                            <Fragment key={option.id}>
                              <InputsToRender
                                attributes={option.attributes}
                                isVip={isVip}
                                setUserName={setUserName}
                                handleCheckboxExtraQuestions={handleCheckboxExtraQuestions}
                                handleSelectExtraQuestions={handleSelectExtraQuestions}
                                optionListSelected={optionListSelected}
                              />
                            </Fragment>
                          )
                        }
                        return <Fragment key={option.id}></Fragment>
                      })}
                  </>
                )}

              {attribute.type === 'select' &&
                attribute?.optionsSelectElement?.length > 0 &&
                !(attribute.isHiddenToVip && isVip) && (
                  <>
                    <SelectField
                      attribute={attribute}
                      options={setOptions(
                        attribute.optionsSelectElement.sort(
                          (a, b) => (a.weight as number) - (b.weight as number)
                        )
                      )}
                      isMulti={attribute.isMulti}
                      handleOptionSelected={(option) => {
                        handleSelectExtraQuestions(option, attribute)
                      }}
                    />
                    {attribute.optionsSelectElement.length > 0 &&
                      attribute.optionsSelectElement.map((option) => {
                        const optSelect: OptionListI | undefined = optionListSelected?.find(
                          (op) => op.value === option.value && attribute.id.toString() === op.attName
                        )

                        if (option.attributes.length > 0 && optSelect) {
                          return (
                            <Fragment key={option.id}>
                              <InputsToRender
                                attributes={option.attributes}
                                isVip={isVip}
                                setUserName={setUserName}
                                handleCheckboxExtraQuestions={handleCheckboxExtraQuestions}
                                handleSelectExtraQuestions={handleSelectExtraQuestions}
                                optionListSelected={optionListSelected}
                                handleEquipmentSelection={handleEquipmentSelection}
                              />
                            </Fragment>
                          )
                        }
                        return <Fragment key={option.id}></Fragment>
                      })}
                  </>
                )}

              {attribute.type === 'table' && !(attribute.isHiddenToVip && isVip) && (
                <TableField
                  attribute={attribute}
                  isVip={isVip}
                  handleEquipmentSelection={handleEquipmentSelection}
                />
              )}

              {attribute.type === 'download' &&
                attribute?.optionsSelectElement?.length > 0 &&
                !(attribute.isHiddenToVip && isVip) && <DownloadField attribute={attribute} />}

              {attribute.type === 'radio' &&
                attribute?.optionsSelectElement?.length > 0 &&
                !(attribute.isHiddenToVip && isVip) && (
                  <>
                    <RadioField
                      attribute={attribute}
                      handleOptionSelected={(option) => {
                        handleSelectExtraQuestions(option, attribute)
                      }}
                    />
                    {attribute.optionsSelectElement.length > 0 &&
                      attribute.optionsSelectElement.map((option) => {
                        const optSelect: OptionListI | undefined = optionListSelected?.find(
                          (op) => op.value === option.value && attribute.id.toString() === op.attName
                        )
                        if (option.attributes.length > 0 && optSelect) {
                          return (
                            <Fragment key={option.id}>
                              <InputsToRender
                                attributes={option.attributes}
                                isVip={isVip}
                                setUserName={setUserName}
                                handleCheckboxExtraQuestions={handleCheckboxExtraQuestions}
                                handleSelectExtraQuestions={handleSelectExtraQuestions}
                                optionListSelected={optionListSelected}
                              />
                            </Fragment>
                          )
                        }
                        return <Fragment key={option.id}></Fragment>
                      })}
                  </>
                )}

              {attribute.type === 'date' && !(attribute.isHiddenToVip && isVip) && (
                <DateField attribute={attribute} />
              )}
            </Fragment>
          ))}
    </>
  )
}
