import { RemoteGetCategoryList } from 'data/usecases'
import { GetCategoryList } from 'domain/usecases'
import { getTokenAdapter } from 'main/adapters'
import { makeAxiosHttpClient, makeConnectorApiUrl } from 'main/factories/http'

export const makeGetCategoryList = (): GetCategoryList =>
  new RemoteGetCategoryList(
    makeAxiosHttpClient(),
    makeConnectorApiUrl('/category'),
    getTokenAdapter()
  )
