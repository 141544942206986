import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { ModalContent } from 'presentation/components'
import { TextField } from 'presentation/components/Fields'
import { useAdmManagementContext } from 'presentation/context'
import { useHandleError } from 'presentation/hooks'

import { ModalBodyForm } from 'presentation/components/Modal/styles'

export interface IFormInput {
  name: string
  login: string
}

type Props = {
  toggleModal: () => void
  updateData: () => void
}

const AddAdminModal = ({ toggleModal, updateData }: Props) => {
  const methods = useForm<IFormInput>()
  const { createNewAdmin } = useAdmManagementContext()
  const { handleError } = useHandleError()
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    setIsLoading(true)
    try {
      await createNewAdmin.post(data.name, data.login.trim().toLowerCase())
      updateData()
    } catch (error: any) {
      handleError(error)
    } finally {
      setIsLoading(false)
      methods.reset()
      toggleModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Novo administrador"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <ModalBodyForm>
          <TextField
            attribute={{
              id: 'name',
              question: 'Digite o nome:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false
            }}
          />
          <TextField
            attribute={{
              id: 'login',
              question: 'Digite o login:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false
            }}
          />
        </ModalBodyForm>
      </ModalContent>
    </FormProvider>
  )
}

export default AddAdminModal
