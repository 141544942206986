import styled from 'styled-components'

export const WrapperDate = styled.div`
  input[type='text'] {
    border-radius: 0px;
    padding: 0 0.5rem;
    font-weight: bold;
    font-size: 0.875rem !important;
    &::placeholder {
      font-weight: bold;
      font-style: normal;
      color: ${({ theme }) => theme.colors.blue._800};
      opacity: 1;
      font-size: 0.75rem;
    }
  }

  input[type='date'] {
    border-radius: 0px;
    padding: 0 0.5rem;
    font-weight: bold;
    font-size: 0.875rem;
  }
`
export const ContainerLocationSearch = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .search_location_ticket {
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
    input[type='search'] {
      min-width: 20rem;
      border-radius: 0px;
      padding: 0 0.5rem;
      font-weight: bold;
      font-size: 0.875rem;
      &::placeholder {
        font-weight: bold;
        font-style: normal;
        color: ${({ theme }) => theme.colors.black};
        opacity: 1;
        font-size: 0.75rem;
      }
    }

    .loading {
      position: absolute;
      float: right;
      margin-left: auto;
      right: 2%;
      margin-bottom: 0.8rem;
    }
  }

  .input-search-list {
    position: absolute;
    top: 95%;
    z-index: 1000;
    padding: 0 0.3rem;
    width: 100%;
    display: block;
    height: fit-content;
    max-height: 10rem;
    overflow-y: scroll;
    overflow-x: hidden;
    color: ${({ theme }) => theme.colors.gray._500};
    border: 1px solid ${({ theme }) => theme.colors.gray._400};
    background-color: #ffffff;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

    /* background-clip: padding-box;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out; */
    ul {
      list-style-type: none;

      li {
        margin-top: 0.5rem;
        cursor: pointer;
        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  // DELETE
  .input-search-item {
    margin-top: 0.5rem;
    ul {
      list-style-type: none;
      li {
        cursor: pointer;
        &:hover {
          font-weight: bold;
        }
      }
    }
  }
`
