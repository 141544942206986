import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { ModalContent } from 'presentation/components'
import { EditorContentField, SelectField, TextField } from 'presentation/components/Fields'
import { FaqModel } from 'domain/models'
import { useAdmFaqContext } from 'presentation/context'
import { buildImageList, checkDataChanges, getUrlsImage, replaceBase64ByUrl } from './utils'
import { IFormInput } from './AddFaqModal'
import { EditorImageProps } from '../Fields/EditorContentField'
import { useGetFormOptions, useHandleError } from 'presentation/hooks'

import { ModalBodyForm } from 'presentation/components/Modal/styles'
import { customFormSelectStyles } from '../Select/styles'

type Props = {
  toggleModal: () => void
  faqSelected: FaqModel
  updateData: () => void
}

const EditFaqModal = ({ toggleModal, faqSelected, updateData }: Props) => {
  const defaultValues: IFormInput = {
    title: faqSelected.title,
    content: faqSelected.content,
    formsAssociated: faqSelected.formsAssociated
  }
  const methods = useForm<IFormInput>({ defaultValues })
  const { saveMultipleFiles, deleteMultipleFiles, getFormList, editFaq } = useAdmFaqContext()
  const { isLoadingFormOptions, formOptions, getFormListOptions, formOptionsSelected } =
    useGetFormOptions({
      getFormList
    })
  const { handleError } = useHandleError()

  const [isLoading, setIsLoading] = useState(false)
  const [images, setImages] = useState<EditorImageProps[]>([])
  const [files, setFiles] = useState<File[]>([])

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    const hasChanges = checkDataChanges({ data, faqSelected })
    if (!hasChanges) {
      toast.warn('Nada a ser editado!')
      toggleModal()
      return
    }

    if (data.content === '<p><br></p>') {
      methods.setError('content', { type: 'error', message: '* Campo obrigatório' })
      return
    }

    setIsLoading(true)
    let urlList = []
    const list: string[] = []

    try {
      urlList = await getUrlsImage({ files, saveMultipleFiles })
      urlList.forEach((el) => list.push(el.url))
      const newContent = replaceBase64ByUrl({ images, content: data.content, urlList })
      const { newList, filesToRemove } = buildImageList({
        faqSelected,
        content: data.content,
        urlList: list
      })

      const dto = {
        title: data.title,
        content: newContent || data.content,
        files: newList,
        formsAssociated: data.formsAssociated
      }

      await editFaq.put({ id: faqSelected.id.toString(), dto })

      if (filesToRemove.length > 0) {
        await deleteMultipleFiles.all(filesToRemove)
      }
      updateData()
    } catch (error: any) {
      if (urlList.length > 0) {
        await deleteMultipleFiles.all(list)
      }
      handleError(error)
    } finally {
      setIsLoading(false)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  useEffect(() => {
    getFormListOptions(faqSelected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Editar FAQ"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <ModalBodyForm>
          <SelectField
            attribute={{
              id: 'formsAssociated',
              question: 'Qual formulário esta Faq estará relacionada?',
              optionsSelectElement: [],
              required: false,
              isHiddenToVip: false,
              placeholder: 'Adicione o título...'
            }}
            options={formOptions}
            isLoadingData={isLoadingFormOptions}
            menuPlacement={'bottom'}
            customSelectStyles={customFormSelectStyles}
            isMulti={true}
            defaultValue={formOptionsSelected}
          />

          <TextField
            attribute={{
              id: 'title',
              question: 'Adicione o título:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false,
              placeholder: 'Adicione o título...'
            }}
          />
          <EditorContentField
            fieldName="content"
            validations={{
              required: {
                value: true,
                message: '* Campo obrigatório'
              }
            }}
            images={images}
            setImages={setImages}
            files={files}
            setFiles={setFiles}
            value={faqSelected.content}
            label="Edite o conteúdo da faq"
          />
        </ModalBodyForm>
      </ModalContent>
    </FormProvider>
  )
}

export default EditFaqModal
