import { CategoryType, ICategory } from 'domain/models'
import { OptionListI } from 'presentation/hooks'

type PropsResponse = {
  category: ICategory | null
  title: string | null
  value: string | null
}

export const getCategoryInfoFromSelectedOption = (optionsList: OptionListI[]): PropsResponse => {
  const option = optionsList.find((option) => option.categoryId !== null)
  if (option?.categoryId) {
    return {
      category: {
        name: option.categoryName as string,
        type: option.type as unknown as CategoryType,
        group: option.group as string,
        status: option.openingStatus ?? 'DIR',
        categoryId: option.categoryId,
        entityId: option.entityId ? option.entityId : ''
      },
      title: option?.title,
      value: option?.value
    }
  }

  return { category: null, title: null, value: null }
}
