import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { ModalContent } from 'presentation/components'
import { TextField } from 'presentation/components/Fields'
import { UserAdminModel } from 'domain/models'
import { getCurrentAccountAdapter } from 'main/adapters'
import { useAdmManagementContext } from 'presentation/context'
import { useHandleError } from 'presentation/hooks'

import { ModalBodyForm } from 'presentation/components/Modal/styles'
import { IFormInput } from './AddAdminModal'

type Props = {
  toggleModal: () => void
  adminSelected: UserAdminModel
  updateData: () => void
}

const EditAdminModal = ({ toggleModal, adminSelected, updateData }: Props) => {
  const defaultValues = {
    name: adminSelected?.name,
    login: adminSelected?.userLogin
  }
  const methods = useForm<IFormInput>({
    defaultValues
  })
  const { editAdmin } = useAdmManagementContext()
  const { handleError } = useHandleError()
  const [isLoading, setIsLoading] = useState(false)
  const user = getCurrentAccountAdapter()

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    setIsLoading(true)
    try {
      const isSameName = data.name.toLowerCase() === adminSelected?.name.toLowerCase()
      const isSameLogin = data.login.toLowerCase() === adminSelected?.userLogin.toLowerCase()

      if (isSameLogin && isSameName) {
        setIsLoading(false)
        methods.reset()
        toggleModal()
        toast.warn('Nada a ser editado!')
        return
      }

      const name = data.name || adminSelected?.name
      const userLogin = data.login || adminSelected?.userLogin

      await editAdmin.put({
        dto: { name, userLogin },
        id: adminSelected.id.toString()
      })
      updateData()
    } catch (error: any) {
      handleError(error)
    } finally {
      setIsLoading(false)
      methods.reset({
        name: '',
        login: ''
      })
      toggleModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  useEffect(() => {
    if (adminSelected) {
      methods.setValue('name', adminSelected.name)
      methods.setValue('login', adminSelected.userLogin)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminSelected])

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Editar administrador"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <ModalBodyForm>
          <TextField
            attribute={{
              id: 'name',
              question: 'Digite o nome:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false,
              placeholder: adminSelected?.name
            }}
          />
          {user.login !== adminSelected.userLogin && (
            <TextField
              attribute={{
                id: 'login',
                question: 'Digite o login:',
                optionsSelectElement: [],
                required: true,
                isHiddenToVip: false,
                placeholder: adminSelected?.userLogin
              }}
            />
          )}
        </ModalBodyForm>
      </ModalContent>
    </FormProvider>
  )
}

export default EditAdminModal
