import { ChangeEvent, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { AttributeModel, OptionModel } from 'domain/models'

import { ErrorMessage, FormInputCheckboxMultiSelect } from './styles'

type Props = {
  attribute: AttributeModel
  handleOptionsSelected: (e: ChangeEvent<HTMLInputElement>, option: OptionModel) => void
}

const CheckboxField = ({ attribute, handleOptionsSelected }: Props) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()
  const [checkboxesSelected, setCheckboxesSelected] = useState<string[]>([])

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, option: OptionModel) => {
    if (option.attributes.length > 0) {
      handleOptionsSelected(e, option)
    }
    if (checkboxesSelected.some((el) => el === option.value)) {
      const newOptions = checkboxesSelected.filter((el) => el !== option.value)
      setCheckboxesSelected(newOptions)
      return newOptions
    }
    const newOptions = [...checkboxesSelected, option.value]
    setCheckboxesSelected(newOptions)
    return newOptions
  }

  return (
    <Controller
      name={attribute.id.toString()}
      control={control}
      rules={{ required: attribute.required }}
      render={({ field: { onChange } }) => (
        <FormInputCheckboxMultiSelect $isMulti={attribute.optionsSelectElement.length > 1}>
          <div className="wrapper-checkbox">
            <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
            <div className="checkbox-list">
              {attribute.optionsSelectElement
                .sort((a, b) => (a.weight as number) - (b.weight as number))
                .map((option: OptionModel) => (
                  <div key={option.id} className="input-checkbox">
                    <input
                      type="checkbox"
                      name={option.value}
                      onChange={(e) => {
                        onChange(handleCheckbox(e, option))
                      }}
                    />
                    <p>{option.value}</p>
                  </div>
                ))}
            </div>
          </div>
          {errors[attribute.id.toString()]?.type === 'required' && (
            <ErrorMessage>* Campo Obrigatório</ErrorMessage>
          )}
        </FormInputCheckboxMultiSelect>
      )}
    />
  )
}

export default CheckboxField
