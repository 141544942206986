import Spinner from 'presentation/components/Loadings/Spinner'
import { KeyboardEvent, useEffect, useState } from 'react'
import { ContainerLocationSearch } from './styles'
import { useGetLocation } from 'presentation/hooks/useGetLocation'
import { SearchLocation } from 'domain/usecases/user/search-location'

type Props = {
  defaultValue: string
  handleSearch: (value: string) => void
  placeholder: string
  getLocation: SearchLocation
}

const LocationSearch = ({ handleSearch, placeholder, getLocation, defaultValue }: Props) => {
  const { locationList, isLoading, searchLocation, setLocationList, setIsLoading } = useGetLocation(
    {
      getLocation
    }
  )
  const [locationSelected, setLocationSelected] = useState<string | null>(defaultValue || null)
  const [locationNameTyped, setLocationNameTyped] = useState('')

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Delete' || e.key === 'Backspace') {
      setLocationSelected(null)
      setLocationNameTyped('')
    }
  }

  const handleLocationSelect = (location: string) => {
    handleSearch(location)
    setLocationSelected(location)
    setLocationList([])
  }

  useEffect(() => {
    if (locationSelected === null && locationNameTyped !== '') {
      setIsLoading(true)
      const getData = setTimeout(() => {
        searchLocation({
          name: locationNameTyped,
          limit: 200
        })
      }, 3000)

      return () => {
        clearTimeout(getData)
      }
    } else {
      setIsLoading(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationNameTyped, locationSelected])

  return (
    <ContainerLocationSearch>
      <div className="search_location_ticket">
        <input
          type="search"
          name="searchLocationInput"
          autoComplete="off"
          placeholder={placeholder}
          value={locationSelected ?? locationNameTyped}
          onChange={(e) => {
            setLocationSelected(null)
            setLocationNameTyped(e.target.value)
          }}
          onKeyDown={(e) => {
            handleKeyDown(e)
          }}
        />
        {isLoading && (
          <span className="loading">
            {' '}
            <Spinner size={20} marginTop="1rem" />
          </span>
        )}
      </div>
      {!isLoading && locationList.length > 0 && (
        <div className="input-search-list">
          <ul>
            <li onClick={() => {
              handleLocationSelect('Todas')
            }}>
              Todas
            </li>
            {locationList.map((location, index) => (
              <li
                key={index}
                onClick={() => {
                  handleLocationSelect(location.locationName)
                }}
              >
                {location?.locationName.toUpperCase()}
              </li>
            ))}
          </ul>
        </div>
      )}

      {!isLoading && locationList.length === 0 && locationNameTyped && (
        <div className="input-search-list">
          <div className="input-search-item">Localidade não encontrada</div>
        </div>
      )}
    </ContainerLocationSearch>
  )
}

export default LocationSearch
