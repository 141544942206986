import { IAttributeProps } from 'presentation/components/FormAdmin/AdmFields/AttributeFieldList'
import { AttributeModel, AttributeOptionModel } from '../../../../domain/models'
import { v4 as uuidv4 } from 'uuid'

type AttToShowProps = {
  attrsCreated: IAttributeProps[]
  attrsReused: any[]
}

export const setAttributeToShow = ({ attrsCreated, attrsReused }: AttToShowProps): AttributeModel[] => {
  const _attrCreated = [...attrsCreated]
  const list: AttributeModel[] = [...attrsReused]
  _attrCreated.forEach((attr) => {
    const _options: AttributeOptionModel[] = []
    if (attr?.optionsSelectElement.length > 0) {
      attr?.optionsSelectElement.forEach((opt) => {
        if (opt?.attributes.length > 0) {
          const _atts = setAttributes(opt.attributes)
          _options.push({
            ...opt,
            attributes: _atts ?? [],
            id: uuidv4()
          })
        } else {
          _options.push({
            ...opt,
            id: uuidv4()
          })
        }
      })
    }
    list.push({
      ...attr,
      id: uuidv4(),
      optionsSelectElement: [..._options]
    } as AttributeModel)
  })

  return list
}

const setAttributes = (attributes: IAttributeProps[], previousAtt: AttributeModel[] = []) => {
  const newData: AttributeModel[] = previousAtt
  attributes.forEach((attribute) => {
    const _options: AttributeOptionModel[] = []
    if (attribute?.optionsSelectElement && attribute?.optionsSelectElement.length > 0) {
      attribute?.optionsSelectElement.forEach((option) => {
        if (option?.attributes.length > 0) {
          setAttributes(option?.attributes, newData)
        } else {
          _options.push({
            ...option,
            id: uuidv4()
          })
        }
      })
    }

    newData.push({
      ...(attribute as AttributeModel),
      optionsSelectElement: _options,
      id: uuidv4()
    })
  })

  return newData
}
