/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useEffect, useRef, useState } from 'react'
import {
  Controller,
  FieldArrayWithId,
  FieldValues,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useFormContext
} from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleChevronDown,
  faCircleChevronUp,
  faGrip,
  faPenToSquare,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { AttributeOptionModel } from '../../../../domain/models'
import { ModalWrapper } from 'presentation/pages/Admin/styles'
import { WrapperOptionItem, WrapperOptionContent } from './styles'
import { v4 as uuidv4 } from 'uuid'

type Props = {
  optIndex: number
  remove: UseFieldArrayRemove
  id: string | number
  option: FieldArrayWithId<FieldValues, any, '_id'> | null
  update: UseFieldArrayUpdate<FieldValues, any>
  optionsSelectElementFieldName: any
  firstLevel?: boolean
  hideCategory?: boolean
}

type UpdateDateProps = {
  isChangeAttributes: boolean
  isChangeCategory: boolean
}

const SelectItem = ({
  optIndex,
  remove,
  id,
  option,
  update,
  optionsSelectElementFieldName,
  firstLevel = false,
  hideCategory = false
}: Props) => {
  const { control, setValue, getValues } = useFormContext()
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }
  const refOpenOptionContentModal = useRef<HTMLDialogElement>(null)
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const [isOpenOptionsContentModal, setIsOpenOptionContentModal] = useState(false)
  const _option = { ...option } as AttributeOptionModel
  const isShowIconDropdown =
    ((_option?.attributes && _option?.attributes?.length > 0) || _option.categoryId) && firstLevel
  const isShowIconEdit =
    (!_option?.attributes || _option?.attributes?.length === 0) &&
    !_option.categoryId &&
    firstLevel

  const toggleOpenAttributeModal = async () => {
    if (refOpenOptionContentModal.current) {
      if (isOpenOptionsContentModal) {
        setIsOpenOptionContentModal(false)
        refOpenOptionContentModal?.current.close()
        return
      }

      setIsOpenOptionContentModal(true)
      refOpenOptionContentModal?.current.showModal()
    }
  }

  const handleUpdateData = ({ isChangeAttributes, isChangeCategory }: UpdateDateProps) => {
    update(optIndex, {
      id: _option?.id as string,
      title: _option?.title as string,
      value: _option?.value,
      attributes: isChangeAttributes ? [...getValues(`${optionsSelectElementFieldName}.${optIndex}.attributes`)] : _option.attributes,
      weight: _option?.weight as number,
      propertyId: _option?.propertyId as null,
      categoryId: isChangeCategory ? getValues(`${optionsSelectElementFieldName}.${optIndex}.categoryId`) : (_option?.categoryId as null),
      type: isChangeCategory ? getValues(`${optionsSelectElementFieldName}.${optIndex}.type`) : (_option?.type as boolean),
      group: isChangeCategory ? getValues(`${optionsSelectElementFieldName}.${optIndex}.group`) : (_option?.group as null),
      categoryName: isChangeCategory ? getValues(`${optionsSelectElementFieldName}.${optIndex}.categoryName`) : (_option?.categoryName as null),
      openingStatus: isChangeCategory ? getValues(`${optionsSelectElementFieldName}.${optIndex}.openingStatus`) : (_option?.openingStatus as string)
    })
  }

  useEffect(() => {
    const isChangeAttributes =
      JSON.stringify(_option?.attributes) !==
      JSON.stringify(getValues(`${optionsSelectElementFieldName}.${optIndex}.attributes`))
    const isChangeCategory =
      JSON.stringify(_option?.categoryId) !==
      JSON.stringify(getValues(`${optionsSelectElementFieldName}.${optIndex}.categoryId`))

    if (!isOpenOptionsContentModal && (isChangeAttributes || isChangeCategory)) {
      handleUpdateData({ isChangeAttributes, isChangeCategory })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenOptionsContentModal])

  return (
    <>
      <WrapperOptionItem ref={setNodeRef} style={style}>
        <div className="section-option">
          <div className="values-opt">
            <button
              type="button"
              className="btn-move-opt"
              title="Mover"
              {...attributes}
              {...listeners}
            >
              <FontAwesomeIcon icon={faGrip} rotation={90} />
            </button>
            <FontAwesomeIcon icon={faCircle} />

            <Controller
              name={`${optionsSelectElementFieldName}.${optIndex}.value`}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <div className="section-type">
                  <input
                    type="text"
                    placeholder="Opção"
                    defaultValue={_option?.value}
                    onChange={(e: any) => {
                      setValue(
                        `${optionsSelectElementFieldName}.${optIndex}.value`,
                        e.target.value
                      )
                    }}
                    onBlur={(e) => {
                      update(optIndex, {
                        id: _option?.id as string,
                        title: e.target.value,
                        value: e.target.value,
                        attributes: _option?.attributes as [],
                        weight: _option?.weight as number,
                        propertyId: _option?.propertyId as null,
                        categoryId: _option?.categoryId as null,
                        type: _option?.type as boolean,
                        group: _option?.group as null,
                        categoryName: _option?.categoryName as null,
                        openingStatus: _option?.openingStatus as string,
                        name: uuidv4()
                      })
                    }}
                  />
                  {isShowIconDropdown && (
                    <button
                      type="button"
                      className="btn-dropdown"
                      onClick={() => {
                        setIsOpenDropdown(!isOpenDropdown)
                      }}
                      title="Vizualizar perguntas"
                    >
                      {isOpenDropdown ? (
                        <FontAwesomeIcon icon={faCircleChevronUp} />
                      ) : (
                        <FontAwesomeIcon icon={faCircleChevronDown} />
                      )}
                    </button>
                  )}

                </div>
              )}
            />
          </div>
          <button
            type="button"
            className="btn-remove-opt"
            onClick={() => {
              remove(optIndex)
            }}
            title="Remover opção"
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        {isOpenDropdown && (
          <WrapperOptionContent>
            {_option.categoryId && (
              <div className="option-category">
                <label>Categoria associada:</label>
                <span>{_option.categoryName}</span>
              </div>
            )}
            {_option?.attributes.length > 0 && (
              <div className="opt-attributes">
                <label>Perguntas associadas:</label>
                {_option?.attributes.map((attribute: any) => (
                  <div key={attribute.question} className="opt-attribute">
                    <span className="att-question">
                      <FontAwesomeIcon icon={'fa-solid fa-circle' as IconProp} />
                      <p>{attribute.question}</p>
                    </span>
                  </div>
                ))}
              </div>
            )}

            <button
              type="button"
              className="btn-show-details"
              onClick={() => {
                toggleOpenAttributeModal()
              }}
            >
              Ver detalhes
            </button>
          </WrapperOptionContent>
        )}
      </WrapperOptionItem>
      <ModalWrapper ref={refOpenOptionContentModal} $width={'80%'}>
      </ModalWrapper>
    </>
  )
}

export default SelectItem
