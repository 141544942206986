import { useController, useFormContext } from 'react-hook-form'
import { AttributeModel } from 'domain/models'
import { ErrorMessage, FormInputText } from './styles'

type Props = {
  attribute: AttributeModel
}

const TextField = ({ attribute }: Props) => {
  const { control, formState: { errors } } = useFormContext()

  const {
    field,
    fieldState
  } = useController({
    name: attribute.id.toString(),
    control,
    rules: {
      required: {
        value: attribute.required ?? false,
        message: '* Campo obrigatório'
      },
      maxLength: {
        value: attribute.maxLength ?? 25000,
        message: `* Máximo ${attribute.maxLength ?? 25000} caracteres permitidos`
      }
    }
  })

  return (
    <FormInputText>
      <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
      <input
        type="text"
        id={attribute.id.toString()}
        {...field} // Conecta o campo ao react-hook-form
      />
      {fieldState.error && (
        <ErrorMessage>{fieldState.error.message}</ErrorMessage>
      )}
    </FormInputText>
  )
}

export default TextField
